@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'GreatLakesNF';
    src: url('./fonts/GreatLakesNF.ttf') format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&family=Montserrat:wght@400;500;600;700&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}


.font-teko {
    font-family: 'Teko', sans-serif;
}


.font-greatlakes {
    font-family: GreatLakesNF;
}

.bg-img {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./img/bg1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.flip {
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}


.reviews .slick-slide > div {
    margin: 0 10px;
}
.reviews .slick-list {
    margin: 0 -10px;
}

@media only screen and (min-width: 1024px) {

    .reviews .slick-slide > div {
        margin: 0 15px;
    }
    .reviews .slick-list {
        margin: 0 -15px;
    }
}

.bg-image {
    background-image: url('./img/bg_coming_soon.jpg');
    width: 100vw;
    min-height: 100vh;
    /*min-height: 100vw;*/
}

.hide {
    display: block;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 60px;
}

.hide_press {
    display: block;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 65px;
}

.swiper-pagination-bullet-active {
    background-color: #fff !important;
}

#highlightButton {
    transition: background-color 0.3s ease;
}

#highlightButton:hover {
    background-color: rgb(182, 129, 111, 1);
}

@keyframes highlightAnimation {
    0% {
        transform: scale(1);
        box-shadow: 0 0 10px rgba(182, 129, 111, 1);
    }
    50% {
        transform: scale(1.1);
        box-shadow: 0 0 20px rgba(182, 129, 111, 1);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 10px rgba(182, 129, 111, 1);
    }
}

.highlight {
    animation: highlightAnimation 1s infinite; /* 2s durată, infinite pentru a se repetă */
}